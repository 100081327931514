<template>
  <div class="container my-14 lg:my-30">
    <template v-if="data.button?.length">
      <div class="mb-10 flex flex-col justify-between md:flex-row lg:mb-0">
        <div class="mb-8 lg:mb-10">
          <Heading :content="data.title" importance="3" class="!mb-0 lg:max-w-3xl" />
          <div
            v-if="data.html"
            v-html="data.html"
            class="mt-3 max-w-3xl text-base lg:mt-5 lg:text-lg"
          ></div>
        </div>

        <div>
          <Button
            v-for="button in data.button"
            :isType="button.primary ? 'primary' : 'secondary'"
            :key="button.id"
            :label="button.title"
            :target="button.externalLink && '_blank'"
            :to="
              button.pageLink !== null
                ? localePathByType(
                    button.pageLink?.__typename as string,
                    button.pageLink?.slug as string
                  )
                : button.externalLink
            "
          />
        </div>
      </div>
    </template>
    <template v-else>
      <Heading :content="data.title" class="mx-auto max-w-4xl text-center" />
    </template>

    <ArticleList :data="relatedArticles" isScrollable />
  </div>
</template>

<script setup lang="ts">
const { localePathByType } = useLocalePathByType()

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const { locale } = useI18n()
const relatedArticles = ref([])

const filterAny = props.data.categories?.length ? props.data.categories.map(x => x.id) : []

const { data: articles } = await useAsyncGql('articles', {
  // @ts-ignore
  locale,
  first: props.data.articlesCount,
  anyIn: filterAny,
  options: {
    initialCache: false
  }
})

if (articles.value) {
  relatedArticles.value = articles.value.allArticles
}

// Add CMS Overrides
if (Array.isArray(props.data.overrideArticles) && props.data.overrideArticles.length) {
  relatedArticles.value.unshift(...props.data.overrideArticles)
  relatedArticles.value = relatedArticles.value.slice(0, props.data.articlesCount)
}
</script>
